import {dDown_Trade} from '../commonDataSource';
import {processCriteriaFormItems} from '../helper';

export function getFormItems(mode) 
{
    //  dataType: for FilterBuilder only
    //  editorTemplate: for FilterBuilder only when custom editor is used
    // caption: for FilterBuilder only
    // defaultCriteria: for form criteria only if default criteria will be set
    // searchOperator: for form criteria only if custom operator is used
    return processCriteriaFormItems([
        
        {                    
            dataField: 'trade',
            editorType: 'dxSelectBox',
            dataType: 'string',
            validationRules:[{type:"required", message:"It is required"}],
            editorOptions: {
                dataSource: dDown_Trade(),
                displayExpr: 'trade',
                valueExpr:'trade',
                searchEnabled:'true',
                searchMode:'contains',
                searchExpr:'trade',
                searchTimeout:'200',
                minSearchLength:'2',
                showDataBeforeSearch:'true',
                showClearButton:'true'
            },
            searchOperator: '=',
            label: {text: 'Tradelane'},
            colSpan: 2,
            visible: true,
            defaultCriteria: 'FE-N.Eur',
        }
        
        
      /* 
        ,
    
        {                    
            dataField: 'depWeek',
            editorType: 'dxSelectBox',
            dataType: 'number',
            editorOptions: {
                dataSource: dDown_CapacityDepWeek(),
                displayExpr: 'depWeek',
                valueExpr:'depWeek',
                searchEnabled:'true',
                searchMode:'contains',
                searchExpr:'trade',
                searchTimeout:'200',
                minSearchLength:'2',
                showDataBeforeSearch:'true',
                showClearButton:'true'
            },
            searchOperator: 'between',
            label: {text: 'Departure Week'},
            colSpan: 1,
            visible: true
        }
        */
       
        ], mode);
}