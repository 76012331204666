import React from 'react';
import Form, { SimpleItem, GroupItem } from 'devextreme-react/form';
import colors from '../../../../utils/colors';
import TabPanel, { Item } from "devextreme-react/tab-panel";
import {Button} from 'devextreme-react';
import DataGrid, { Column, Pager, Paging } from 'devextreme-react/data-grid';
import { getFirebaseIdToken } from "../../../../utils/auth";
import Chart, { CommonSeriesSettings, Size, Series, Crosshair, ArgumentAxis, Legend, TickInterval, Label } from 'devextreme-react/chart';
import { Box, Flex } from '../../../Layout';
import {
  operatorShipSummaryDataSource,
  OperatorCurrentFleetDataSource, 
  OperatorOrderbookDataSource, 
  OperatorFleetChartDataSource, 
  OperatorServiceDataSource,
  operatorCapacityHistoryDataSource
  } from '../commonDataSource'
import { Tooltip } from 'devextreme-react/bar-gauge';
import FleetParticular from '../Fleets/FleetParticularForm';
import ServiceDetail from '../Service/ServiceDetail';

const teuRange =[
  '<1,000','1,000-1,999','2,000-2,999','3,000-3,999','4,000-5,099','5,100-7,499','7,500-9,999','10,000-12,499','12,500-15,199 NPX','15,200-17,999','>18,000'
];

class OperatorDetail extends React.Component {
    constructor(props) {
      super(props);
  
      this.state = { 
        visibleSummary: true,
        visibleShipDetail: null, 
        visibleOprDetail: null, 
        visibleSvcDetail: null, 
        particularFilter: null,
        oprGrp: null,
        imageError: false,
        oprName: null,
        oprUrl: null,
        profileHeight: 150
      };
      this.setGrid = (ref) => {
        if (ref != null)
            this.resultGrid = ref.instance;
      };
      this.setOdaGrid = (ref) => {
        if (ref != null)
            this.odaGrid = ref.instance;
      };
      this.setSvcGrid = (ref) => {
        if (ref != null)
            this.SvcGrid = ref.instance;
      };
      this.setChart = (ref) => { 
        if (ref != null)
          this._chart = ref.instance;
      }; 
      this.setCapacityHistoryChart = (ref) => { 
        if (ref != null)
          this.capacityHistoryChart = ref.instance;
      }; 
      this.setOprRecordForm = (ref) => { 
        if (ref != null)
          this.OprForm = ref.instance;
      }; 
      this.setProfileForm = (ref) => { 
        if (ref != null)
          this.profileForm = ref.instance;
      }; 

      if (props.groupCode)
        this.initialFilter = [['operator','=',props.groupCode]];
      else
        this.initialFilter = props.initialFilter;
      operatorCapacityHistoryDataSource.filter(['operatorGroup','=','0']);
      operatorCapacityHistoryDataSource.paginate(false);

      this.load = this.load.bind(this);
      this.resetResult = this.resetResult.bind(this);
      this.onShipSelectionChanged = this.onShipSelectionChanged.bind(this);
      this.onSvcSelectionChanged = this.onSvcSelectionChanged.bind(this);
      this.backFromSubScreen = this.backFromSubScreen.bind(this);
      this.backFromSvcSubScreen = this.backFromSvcSubScreen.bind(this);
      this.showFullProfile = this.showFullProfile.bind(this);
      this.hideFullProfile = this.hideFullProfile.bind(this);
      this.logoRender = this.logoRender.bind(this);
      this.NoLogoErr = this.NoLogoErr.bind(this);
    }

    componentDidMount() {
      this.load(this.initialFilter);
    }

    resetResult() {
      OperatorFleetChartDataSource.filter(['operatorGroup','=', '0']);

      OperatorCurrentFleetDataSource.filter(['operatorGroup','=', '0']);
      this.resultGrid.refresh();

      OperatorOrderbookDataSource.filter(['operatorGroup','=', '0']);
      this.odaGrid.refresh();

      operatorCapacityHistoryDataSource.filter(['operatorGroup','=', '0']);
      this.setState({selectedOperator: null});

      this.OprForm.option('formData',null);
    }
  
    load(initFilter) {
      var thisObj = this;
//      var data = null;
      var filter;
      var filterContains;

      filter = ['operatorGroup','=', initFilter[0][2].substring(0,4)];
      filterContains = ['operatorGroup','contains', initFilter[0][2].substring(0,4)];

      getFirebaseIdToken(this.props.firebase, () => {

//        console.log(filter);

        OperatorCurrentFleetDataSource.filter(filter);
        //thisObj.resultGrid.refresh();

        OperatorOrderbookDataSource.filter(filter);
        //thisObj.odaGrid.refresh();

        OperatorFleetChartDataSource.filter(filter);
        OperatorFleetChartDataSource.load();
//        thisObj._chart.refresh();

        operatorCapacityHistoryDataSource.filter(filter);
        operatorCapacityHistoryDataSource.load();
//        thisObj.capacityHistoryChart.refresh();

        OperatorServiceDataSource.filter(filterContains)
        //thisObj.SvcGrid.refresh();

        operatorShipSummaryDataSource.filter(filter);
        operatorShipSummaryDataSource.load().then(
          (data)=>{
              thisObj.OprForm.option('formData',data[0]);
              thisObj.profileForm.option('formData',data[0]);
              this.setState({ 
                visibleSummary: true, 
                visibleOprDetail: true, 
                oprGrp: data[0].operatorGroup,
                imageError: false,
                oprName: data[0].groupName, 
                oprUrl: data[0].groupWebSite });
            },
          (error) => {
              console.log(error);
            }
        );

      });
    }

    backFromSubScreen() {
      if (this.props.enquiryBase)
        this.props.enquiryBase.setCriteriaVisible(true); 
      this.setState({ visibleShipDetail: null, visibleOprDetail: true, visibleSummary: true })
    }

    backFromSvcSubScreen() {
      if (this.props.enquiryBase)
        this.props.enquiryBase.setCriteriaVisible(true); 
      this.setState({ visibleSvcDetail: null, visibleOprDetail: true, visibleSummary: true })
    }

    onShipSelectionChanged(e) {
      var data = e.selectedRowsData[0];
      var shipFilter = ['vesselId','=','0'];
      if (data) {
        shipFilter = ['vesselId','=',data.vesselId];
//          alert(shipFilter);
        if (this.props.enquiryBase)
          this.props.enquiryBase.setCriteriaVisible(false);
        this.setState({ visibleOprDetail: null, particularFilter: shipFilter, visibleShipDetail: true, visibleSummary: null });
      }
      else {
      }
    }
  
    onSvcSelectionChanged(e) {
      var data = e.selectedRowsData[0];
      var svcFilter = ['lsCode','=','0'];
      if (data) {
        svcFilter = ['lsCode','=',data.lsCode];
//        alert(svcFilter);
        if (this.props.enquiryBase)
          this.props.enquiryBase.setCriteriaVisible(false);
        this.setState({ visibleOprDetail: null, particularFilter: svcFilter, visibleSvcDetail: true, visibleSummary: null });
      }
      else {
      }    
    }

    showFullProfile() {
      this.setState({ visibleOprDetail: null, profileHeight: 550 });
    }

    hideFullProfile() {
      this.setState({ visibleOprDetail: true, profileHeight: 150 });
    }

    
    logoRender(opr) {

      if (!this.state.oprGrp)
        return (<></>);
      var image = null;
      if (!this.state.imageError) {
        image = `/cms/content/images/logos/${this.state.oprGrp}.png`;
      } else {
        image = '/cms/content/images/logos/LINE.png';
      }
      return (
        <img src={image} height="70" onError={this.NoLogoErr} alt="Operator Logo" />
      );
    }

    NoLogoErr() {
      if (!this.state.imageError) {
        this.setState({imageError: true});
      }
    }

    render() {
      return (
        <React.Fragment>
        <div style={{display: (this.state.visibleSummary)?'inline':'none'}} >
        <Box id='oprDetail' width={'100%'}>
          <Flex wrap={['wrap','nowrap','nowrap']}>
          <Box id='summary' width={['100%','34%','34%']}>
            <Flex wrap={['wrap','nowrap','nowrap']}>
              {this.props.returnCallback && <Button style={{marginRight: 10}} icon="back" hint="Back" onClick={this.props.returnCallback} height='30'/>}
              <h3 style={{marginTop: 5}}>Carrier Name: {this.state.oprName}</h3>
            </Flex>        
            <Form 
              colCount={2}
              ref={this.setOprRecordForm}
              readOnly={true}
              >
                  <SimpleItem render={this.logoRender} colSpan={1}></SimpleItem>
                  <GroupItem>
                    <SimpleItem dataField='country' editorType={'dxTextBox'} />
                    <SimpleItem dataField='ownership' editorType={'dxTextBox'} />
                  </GroupItem>
                  <GroupItem colSpan={2} colCount={2}>
                    <SimpleItem dataField='totalTeu' editorType={'dxNumberBox'} editorOptions={{format:{ type: 'fixedPoint', precision: 0}}} />
                    <SimpleItem dataField='totalShip' editorType={'dxNumberBox'}  editorOptions={{format:{ type: 'fixedPoint', precision: 0}}} />
                    <SimpleItem dataField='ownedTeu' editorType={'dxNumberBox'}  editorOptions={{format:{ type: 'fixedPoint', precision: 0}}}/>
                    <SimpleItem dataField='ownedShip' editorType={'dxNumberBox'}  editorOptions={{format:{ type: 'fixedPoint', precision: 0}}}/>
                    <SimpleItem dataField='charteredTeu' editorType={'dxNumberBox'}  editorOptions={{format:{ type: 'fixedPoint', precision: 0}}}/>
                    <SimpleItem dataField='charteredShip' editorType={'dxNumberBox'}  editorOptions={{format:{ type: 'fixedPoint', precision: 0}}}/>
                    <SimpleItem dataField='orderedTeu' editorType={'dxNumberBox'}  editorOptions={{format:{ type: 'fixedPoint', precision: 0}}}/>
                    <SimpleItem dataField='orderedShip' editorType={'dxNumberBox'}  editorOptions={{format:{ type: 'fixedPoint', precision: 0}}}/>
                  </GroupItem>
            </Form>
            <div style={{display: (!this.state.visibleOprDetail)?'inline':'none'}}  >
            <h3>Capacity Operated History</h3>   
            <Chart
              ref={this.setCapacityHistoryChart}
              dataSource={operatorCapacityHistoryDataSource}   
              
              >
              <CommonSeriesSettings type='area'/>
              <Series
                valueField='teuCapacity'
                argumentField='capacityDate'
                color="#BE1E2D"                  
                />
              <Legend visible={false} />
              
              <ArgumentAxis argumentType='datetime'>              
                <Label 
                  format={'MMM-yyyy'}
                  overlappingBehavior='rotate' />
                <TickInterval months={6} />
              </ArgumentAxis>
              <Crosshair enabled={true}>
                <Label visible={true} />
              </Crosshair>
            </Chart>
            </div>
          </Box>
          <Box id='profile' width={['100%','1%','1%']}>
          </Box>
          <Box id='profile' width={['100%','65%','65%']}>
            <Flex wrap={'nowrap'}>
              <Box width={'50%'}>
                <h3>Website: <a href={this.state.oprUrl} target='_blank' rel="noreferrer">{this.state.oprUrl}</a></h3>
              </Box>
              <Box width={'50%'} textAlign='right'>
                <Button
                    width={100}
                    height={28}
                    text="Show History"
                    icon="chevrondown"
                    visible={this.state.visibleOprDetail}
                    onClick={this.showFullProfile}
                  />
                <Button
                    width={100}
                    height={28}
                    text="Hide History"
                    icon="chevronup"
                    visible={!this.state.visibleOprDetail}
                    onClick={this.hideFullProfile}
                  />
              </Box>
            </Flex>
                <Form 
                  colCount={5}
                  ref={this.setProfileForm}
                  readOnly={true}
                  visible={this.state.visibleSummary}
                  >
                  <SimpleItem dataField='affiliates' editorType={'dxTextArea'} colSpan={5} />
                  <SimpleItem dataField='profile' editorType={'dxTextArea'} colSpan={5} editorOptions={{autoResizeEnabled: true, maxHeight:this.state.profileHeight}} />
                </Form>
          </Box>
          </Flex>

          <div style={{display: (this.state.visibleOprDetail)?'inline':'none'}} >
          <Box width={'100%'}>
            <Flex wrap={['wrap','nowrap','nowrap']} id='fleets'>              
              <Box width={['100%','65%','65%']} id='fleets'>
                <TabPanel
                  loop={false}
                  animationEnabled={true}
                  swipeEnabled={true}
                  deferRendering={false}
                  visible={true}
                  >
                <Item title={"Current Fleet"}>

                    <DataGrid
                    elementAttr={{
                      class: "myClass"
                    }}
                    dataSource={OperatorCurrentFleetDataSource}
                    onSelectionChanged={this.onShipSelectionChanged}
                    showBorders={true}
                    selection={{mode: 'single'}}
                    ref={this.setGrid}
                    allowColumnReordering={true}
                    allowColumnResizing={true}
                      >
                    <Paging defaultPageSize={20} />
                    <Pager
                    showInfo={true} />

                    <Column dataField='vesselName'
                      width={200}
                      dataType={'string'}
                    />

                    <Column dataField='teu'
                      width={60}
                      dataType={'number'}
                      caption={'TEU'}
                      format={{type:'fixedPoint',precision:0}}
                      defaultSortOrder={'desc'}
                    />

                    <Column 
                      width={70}
                      dataField='dwt'
                      dataType={'number'}
                      caption='DWT'
                      format={{type:'fixedPoint',precision:0}}
                    />

                    <Column dataField='yearBuilt'
                      width={50}
                      dataType={'number'}
                      caption='Built'
                    />

                    <Column dataField='charterOwn'
                      width={65}
                      caption={'Own/Char'}
                      dataType={'string'}
                    />

                    <Column dataField='trade'
                      width={150}
                      dataType={'string'}
                    />

                    <Column dataField='vesselId'
                      width={100}
                      dataType={'string'}
                      visible={false}
                    />

                    <Column dataField='imoNo'
                      width={100}
                      dataType={'string'}
                      visible={false}
                    />
                  </DataGrid>
                </Item>

                <Item title={'Orderbook'}>
                  <DataGrid
                    elementAttr={{
                      class: "myClass"
                    }}
                    dataSource={OperatorOrderbookDataSource}
                    onSelectionChanged={this.onShipSelectionChanged}
                    showBorders={true}
                    selection={{mode: 'single'}}
                    ref={this.setOdaGrid}
                    allowColumnReordering={true}
                    allowColumnResizing={true}
                      >
                    <Paging defaultPageSize={20} />
                    <Pager
                    showInfo={true} />

                    <Column dataField='vesselName'
                      width={200}
                      dataType={'string'}
                    />

                    <Column dataField='teu'
                      width={60}
                      dataType={'number'}
                      caption={'TEU'}
                      format={{type:'fixedPoint',precision:0}}
                      defaultSortOrder={'desc'}
                    />

                    <Column dataField='yearBuilt'
                      width={50}
                      dataType={'number'}
                      caption='Built'
                    />

                    <Column dataField='shipyardName'
                      width={180}
                      dataType={'string'}
                    />

                    <Column dataField='charterOwn'
                      width={60}
                      caption={'Own/Char'}
                      dataType={'string'}
                    />

                    <Column dataField='vesselId'
                      width={220}
                      dataType={'string'}
                      visible={false}
                    />

                    <Column dataField='imoNo'
                      width={220}
                      dataType={'string'}
                      visible={false}
                    />
                  </DataGrid>
                </Item>

                <Item title={'Services'}>
                  <DataGrid
                    elementAttr={{
                      class: "myClass"
                    }}
                    dataSource={OperatorServiceDataSource}
                    onSelectionChanged={this.onSvcSelectionChanged}
                    showBorders={true}
                    selection={{mode: 'single'}}
                    ref={this.setSvcGrid}
                    allowColumnReordering={false}
                    allowColumnResizing={false}
                    columnAutoWidth={false}
                      >
                    <Paging defaultPageSize={20} />
                    <Pager
                    showInfo={true} />

                    <Column dataField='trade'
                      width={80}
                    />

                    <Column dataField='serviceName'
                      width={150}
                    />

                    <Column dataField='ships'
                      minWidth={300}
                    />

                    <Column dataField='partners'
                      width={150}
                    />

                    <Column dataField='lsCode'
                      width={150}
                      visible={false}
                    />
                  </DataGrid>
                </Item>

                </TabPanel>
              </Box>
              <Box width={['100%','35%','35%']} id='fleetChart'>
                <h3>Breakdown of Number of Ship by TEU Range of {this.state.oprName}</h3>
                <Chart 
                  ref={this.setChart}
                  dataSource={OperatorFleetChartDataSource}            
                  rotated={true}              
                  >
                  <Legend position={'outside'} verticalAlignment={'bottom'} horizontalAlignment={'center'} itemTextPosition='left'/>
                  <Size height={590} />
                  <CommonSeriesSettings type ="stackedBar" argumentField='teuRange' />
                  <Series
                    name='Current'
                    valueField='totalShips'
                    color='#BE1E2D'
                  />
                  <Series
                    name='Ordered'
                    valueField='orderedShips'
                    color='#636466'                            
                  />
                  <ArgumentAxis categories={teuRange} /> 
                  <Tooltip enabled={true} />
                </Chart>
                </Box>
            </Flex>
            </Box>
            </div>
        </Box>
        </div>
 
        {this.state.visibleShipDetail &&
            <FleetParticular
              firebase={this.props.firebase}
              initialFilter={this.state.particularFilter}
              returnCallback={this.backFromSubScreen}
            />
            }

        {this.state.visibleSvcDetail && 
          <ServiceDetail 
            firebase={this.props.firebase}
            showFleet={false}
            enquiryBase={this.props.enquiryBase}
            initialFilter={this.state.particularFilter}
            returnCallback={this.backFromSvcSubScreen}
          />
        }

        </React.Fragment>
      );
    }
  }


  class  OperatorDetailQuery extends React.Component {
    render() {
      if (this.props.groupCode) 
      return (
        <Box bg={colors.primary}>
          
          <Box
              width={[1, 1, 1]}
              m={['2.7rem 0 0 0', '2.7rem 0 0 0', '2.7rem auto 0 auto']}
              px={[0, 0, 0]}
              color={colors.secondary}
              minHeight={'500px'}
          >
  
              <Box m={'20px 40px 20px 40px'}>
                <OperatorDetail {...this.props} />
              </Box>       
  
          </Box>
        </Box>
      );
      else
        return (
          <OperatorDetail {...this.props} />
      );
    }  
  }
  export default OperatorDetailQuery;