import React from 'react';
import DataGrid, { Column, Pager, Paging } from 'devextreme-react/data-grid';
import { getFirebaseIdToken } from "../../../../utils/auth";
import { Box, Flex } from '../../../Layout';
import { fleetFixturesDataSource } from '../commonDataSource';
import FleetParticular from '../Fleets/FleetParticularForm';

class FixtureGrid extends React.Component {
    constructor(props) {
      super(props);
  
      this.state = {selectedRowsData: null, visibleDetail: null};
      this.setGrid = (ref) => {
        if (ref != null)
            this.resultGrid = ref.instance;
      };

      this.onSelectionChanged = this.onSelectionChanged.bind(this);
      this.showParticulars = this.showParticulars.bind(this);
      this.backFromSubScreen = this.backFromSubScreen.bind(this);
      this.load = this.load.bind(this);
    }

    componentDidMount() {
      if (this.props.initialFilter)
        this.load(this.props.initialFilter);
      }
  
    showPopup(popupOption) {
      this.setState({popupOption: popupOption});
    }

    resetResult() {
      getFirebaseIdToken(this.props.firebase,() => {
        fleetFixturesDataSource.filter('vesselId','=', 0);
        this.resultGrid.refresh();
      });
    }
  
    load(filter) {            
       getFirebaseIdToken(this.props.firebase,() => {
        fleetFixturesDataSource.filter(filter);
        this.resultGrid.refresh();
      });
      //this.resultGrid.clearSelection();
    }
  
    onSelectionChanged(e) {
      var data = e.selectedRowsData[0];
      if (data) {
        this.initialFilter = ['vesselId','=',data.vesselId];
        this.props.enquiryBase.setCriteriaVisible(false);
        this.setState({visibleDetail: true});
      }
      else {
      }
    }

    showParticulars() {

    }

    backFromSubScreen() {
      this.props.enquiryBase.setCriteriaVisible(true); 
      this.setState({visibleDetail: null})
    }

    render() {
      return (
        <React.Fragment>
        <Flex>
        <DataGrid
          elementAttr={{
            class: "myClass"
          }}
          dataSource={fleetFixturesDataSource}
          showBorders={true}
          selection={{mode: 'single'}}
          ref={this.setGrid}
          allowColumnReordering={true}
          allowColumnResizing={true}
          columnAutoWidth={false}
          wordWrapEnabled={false}
          rowAlternationEnabled={false}
          onSelectionChanged={this.onSelectionChanged}
          visible={!this.state.visibleDetail}         
            >
          <Paging defaultPageSize={15} />
          <Pager
            showInfo={true} />     

          <Column dataField='dateReported'
            dataType={'date'}
            width={90}
            format={'MMM-yyyy'}
            defaultSortOrder={'desc'}
          />

          <Column dataField='vesselName'
            dataType={'string'}
            width={250}
          />

          <Column dataField='typeOfShip'
            caption='Type'
            dataType={'string'}
            width={70}
          />

          <Column dataField='teu'
            dataType={'number'}
            format={{type:'fixedPoint',precision:0}}
            caption='TEU'
            width={80}
          />

          <Column dataField='dwt'
            dataType={'number'}
            format={{type:'fixedPoint',precision:0}}
            caption='DWT'
            width={90}
          />

          <Column dataField='yearBuilt'
            caption='Built'
            dataType={'number'}
            width={70}
          />

          <Column dataField='gears'
            dataType={'string'}
            width={80}
            visible={false}
            caption={'Gear'}
          />

          <Column dataField='charterer'
            dataType={'string'}
            width={250}
          />

          <Column dataField='estOpenDateEarliest'
            dataType={'date'}
            caption={'Est Open'}
            format={'MMM-yyyy'}
            width={100}
          />

          <Column dataField='periodInMonth'
            caption={'Period'}
            dataType={'string'}
            width={90}
          />

          <Column dataField='currency'
            dataType={'string'}
            width={70}
          />

          <Column dataField='rate'
            caption={'rate'}
            dataType={'string'}
            width={80}
          />
          
          <Column dataField='vesselId'
            dataType={'string'}
            width={90}
            visible={false}
          />

          <Column dataField='imoNo'
            dataType={'string'}
            width={90}
            visible={false}
          />
        </DataGrid>
        </Flex>

        <Flex>
        <Box>
        <div style={this.state.visibleDetail?{}:{display: 'none'}}>
            {this.state.visibleDetail?
            <>
            <FleetParticular
              firebase={this.props.firebase}
              initialFilter={this.initialFilter}
              returnCallback={this.backFromSubScreen}
              />
            </>:null            
            }
        </div>
        </Box>
        </Flex>

        </React.Fragment>
      );
    }
  }

  export default FixtureGrid;