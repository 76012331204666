import React from 'react';
import { Box, Flex } from '../../../Layout';

import VesselLocationMap from './VesselLocationMap';
import { SelectBox } from 'devextreme-react/select-box';
import { TextBox } from 'devextreme-react/text-box';
import {oDataStoreDefaultSettingWithToken} from '../../oDataStoreDefaultSetting';
import DateBox from 'devextreme-react/date-box';
import ODataStore from 'devextreme/data/odata/store';
import DataSource from 'devextreme/data/data_source';
import {oDataStoreDefaultSetting} from '../../oDataStoreDefaultSetting';
import { LoadPanel } from 'devextreme-react/load-panel';
import { Button } from 'devextreme-react/button';
import { fontSize } from 'styled-system';

const mapDataStore = new ODataStore({
  url: `${process.env.GATSBY_ODATA_SERVER_URL}/VesselLocation`,
  ...oDataStoreDefaultSetting
})

class VesselLocation extends React.Component {
    constructor(props) {
      super(props);
      this.DDown_MapTradeSource = new DataSource({
        store: new ODataStore({
          url: `${process.env.GATSBY_ODATA_SERVER_URL}/DDown_MapTrade`,
          ...oDataStoreDefaultSettingWithToken(this.props.firebase)
        })
        ,sort: [{selector:'tradeGrpSeq'}]
      });

      //this.dateNow = this.addDays(new Date((new Date()).toDateString()), -1);
      //this.dateMin = this.addDays(this.dateNow,-27);
      this.onDateValueChanged = this.onDateValueChanged.bind(this);
      this.onTradeChanged = this.onTradeChanged.bind(this);
      this.onLatctrChanged = this.onLatctrChanged.bind(this);
      this.onLngctrChanged = this.onLngctrChanged.bind(this);
      this.onZoomFactorChanged = this.onZoomFactorChanged.bind(this);
      
      this.onLoadMoreClick = this.onLoadMoreClick.bind(this);
      this.load = this.load.bind(this);

      this.noOfMapToShowARound = 20;

      this.state = {
        dateValue: null,
        loadingData: false,
        noOfMapToShow: this.noOfMapToShowARound,
        latctr: 17,
        lngctr: 69,
        zoomFactor: 2,
        useDef: false,
      };
    }
    addDays(baseDate, days) {
      var date = new Date(baseDate.valueOf());
      date.setDate(date.getDate() + days);
      return date;
    }
    componentDidMount() {
      var thisObj = this;
      this.DDown_MapValueDateSource = new DataSource({
        store: new ODataStore({
          url: `${process.env.GATSBY_ODATA_SERVER_URL}/DDown_MapValueDate`,
          onLoaded: function (result) {
            thisObj.setState({dateValue: new Date(result[0].maxValueDate), dateNow: new Date(result[0].maxValueDate), dateMin: new Date(result[0].minValueDate)});
          },
          ...oDataStoreDefaultSetting
        })
      }); 
      this.DDown_MapValueDateSource.load();
      this.setState({trade: 'FE-N.Eur'});
      
    }
    componentDidUpdate() {

    }

    onLoadMoreClick(e) {
      this.setState({
        noOfMapToShow: this.state.noOfMapToShow + this.noOfMapToShowARound,
      });
    }

    onLngctrChanged(e) {
      this.setState({
        lngctr: e.value,
      });
      this.load(this.state.dateValue, this.state.trade);
    } 
    onLatctrChanged(e) {
      this.setState({
        latctr: e.value,
      });
      this.load(this.state.dateValue, this.state.trade);
    } 
    onZoomFactorChanged(e) {
      this.setState({
        zoomFactor: e.value,
      });
      this.load(this.state.dateValue, this.state.trade);
      
    } 
    onTradeChanged(e) {
      this.setState({
        trade: e.value,
      });
      
      this.load(this.state.dateValue, e.value);
    } 
    onDateValueChanged(e) {
      this.setState({
        dateValue: e.value,
      });
      
      this.load(e.value, this.state.trade);
    }    
    load(valueDate, trade) {
      if (!valueDate || !trade) {
        this.setState({mapDataByService: null});
        return;
      }
      this.setState({loadingData: true});
      let newFilter;
      newFilter = [[['trade','=',trade],'and',['valueDate','>=',new Date(valueDate.toDateString())],'and',['valueDate','<',this.addDays(new Date(valueDate.toDateString()),1)]]];
      this.setState({mapDataByService: null});
      var thisObj = this;
      mapDataStore.load({filter: newFilter}).then(
        (data) => {
          thisObj.setState({noOfMapToShow: thisObj.noOfMapToShowARound, loadingData: false, mapDataByService: Object.groupBy(data, (rec) => {return rec.service})});
        },
        (error) => {
          thisObj.setState({mapDataByService: null, loadingData: false});
        }
      );
                
    }

    render() {
      var noOfMapToShow = this.state.noOfMapToShow;
      var arrayMapData = null;
      if (this.state.mapDataByService)
        arrayMapData = Object.entries(this.state.mapDataByService);
      return (
      <Flex wrap={['wrap', 'wrap','nowrap']}>
      <Box width={[1, 1, 1]} m={[0,0,'2rem']}>

        <Flex wrap={['wrap', 'wrap','nowrap']}>
            <div style={{marginRight: 40}}>
            <div>Data as at:</div>
            <DateBox width={"120px"}
                value={this.state.dateValue}
                min={this.state.dateMin}
                max={this.state.dateNow}
                onValueChanged={this.onDateValueChanged} />      
            </div>
            <div>
            <div>Trade:</div>
            <SelectBox dataSource={this.DDown_MapTradeSource}
                  displayExpr="trade"
                  valueExpr="trade"
                  value={this.state.trade}
                  searchEnabled={true}
                  searchMode={'contains'}
                  searchExpr={'trade'}
                  minSearchLength={3}                  
                  searchTimeout={200}
                  showDataBeforeSearch={true}
                  onValueChanged={this.onTradeChanged} />
            
            </div>
            {this.state.useDef?
            <>
            <div>
            <div>Lat:</div>
            <TextBox value={this.state.latctr} onValueChanged={this.onLatctrChanged} />
            </div>
            <div>
            <div>Lng:</div>
            <TextBox value={this.state.lngctr} onValueChanged={this.onLngctrChanged} />
            </div>
            <div>
            <div>zoom:</div>
            <TextBox value={this.state.zoomFactor} onValueChanged={this.onZoomFactorChanged} />
            </div>
            </>:null}
        </Flex>
        <LoadPanel
          shadingColor="rgba(0,0,0,0.4)"
          position={{ of: '#maps' }}

          visible={this.state.loadingData}
          showIndicator={true}
          shading={false}
          showPane={true}
          hideOnOutsideClick={false}
        />
        <div id="maps">
        {arrayMapData? 
          arrayMapData.map((service, index, services) => {
            if (index < Math.min(services.length, noOfMapToShow) && index%2 === 0) {

              if (index + 1 < services.length)

                return (
                  <>
                  <Flex wrap={['wrap', 'wrap','nowrap']}>
                  <Box width={[1,1,0.5]} height={[400,400,630]} mr={[0,0,'1rem']}>
                    <VesselLocationMap useDef={this.state.useDef} defLatctr={this.state.latctr} defLngctr={this.state.lngctr} defZoomFactor={this.state.zoomFactor} service={service[0]} mapData={service[1]} firebase={this.props.firebase}/>                  
                  </Box>

                  
                  <Box width={[1,1,0.5]} height={[400,400,630]} mr={[0,0,'1rem']}>
                    <VesselLocationMap useDef={this.state.useDef} defLatctr={this.state.latctr} defLngctr={this.state.lngctr} defZoomFactor={this.state.zoomFactor} service={services[index + 1][0]} mapData={services[index + 1][1]} firebase={this.props.firebase}/>
                  </Box>
                  </Flex>
                  </>
                )
              else
                return (
                  <>
                  <Flex wrap={['wrap', 'wrap','nowrap']}>
                  <Box width={[1,1,0.5]} height={[400,400,630]} mr={[0,0,'1rem']}>
                    <VesselLocationMap useDef={this.state.useDef} defLatctr={this.state.latctr} defLngctr={this.state.lngctr} defZoomFactor={this.state.zoomFactor} service={service[0]} mapData={service[1]} firebase={this.props.firebase}/>
                  </Box>
                  </Flex>
                  </>
                )
            }

          })
        : null}
        {arrayMapData && arrayMapData.length >= this.state.noOfMapToShow?<Button style={{fontSize:"30px"}}
        text="Click here to load more ..."
        onClick={this.onLoadMoreClick}
        ></Button>: null}
        </div>


      
      </Box>
      </Flex>
      );  
    }
  }

  export default VesselLocation;