import { Box } from '../../../Layout';
import React from 'react';
import { getFirebaseIdToken } from "../../../../utils/auth";
import PivotGrid, { FieldChooser} from 'devextreme-react/pivot-grid';
import Chart, {
  CommonSeriesSettings,
  Series,
  Aggregation,
  Point,
  ArgumentAxis,
  ValueAxis,
  Title,
  Font,
  Legend,
  Label,
  Tooltip,
} from 'devextreme-react/chart';
import ODataStore from 'devextreme/data/odata/store';
import DataSource from 'devextreme/data/data_source';
import {oDataStoreDefaultSettingWithToken} from '../../oDataStoreDefaultSetting';


const customizeTooltip = (pointInfo) => {
  const { aggregationInfo } = pointInfo.point;
  const start = aggregationInfo && aggregationInfo.intervalStart;
  const end = aggregationInfo && aggregationInfo.intervalEnd;
  const handlers = {
    'Average Utilization': (arg) => ({
      text: `${
        !aggregationInfo
          ? `Date: ${arg.argument.toDateString()}`
          : `Interval: ${start.toDateString()} - ${end.toDateString()}`
      }<br/>Utilization: ${arg.value.toFixed(2)} %`,
    }),
    'Utilization range': (arg) => ({
      text: `Interval: ${start.toDateString()} - ${end.toDateString()}<br/>Utilization range: ${
        arg.rangeValue1
      } - ${arg.rangeValue2} %`,
    })
  };
  return handlers[pointInfo.seriesName](pointInfo);
};

class utilizationChart extends React.Component {
    constructor(props) {
      super(props);
      this.setChart = (ref) => { 
        if (ref != null)
          this._chart = ref.instance;
      }; 
      this.calculateAverageFromSunday = this.calculateAverageFromSunday.bind(this);
      this.customizeTooltip = this.customizeTooltip.bind(this);
      this.dataSource = new DataSource({
        store: new ODataStore({
          url: `${process.env.GATSBY_ODATA_SERVER_URL}/capacityUtilProd`,
          onLoaded: function (result) {
            result.forEach(element => {
              element.utilization = Math.round(((element.utilization * 100) + Number.EPSILON) * 100) /100;
            }        
            );
    
          },
          ...oDataStoreDefaultSettingWithToken(this.props.firebase)
        }),
        paginate:false,
        
      });

      this.dataSource.filter('tradeGroupSeq','=',3);    

    }

    componentDidMount() {
      setTimeout(function(){
        
      })
    }

    load(filter) {
      if (!filter || filter.length <= 0) {
        alert('Please input at least one field.');
        return ;
      }

      getFirebaseIdToken(this.props.firebase, () =>  {
        this.dataSource.filter(filter);
        this.dataSource.reload();
      });

    }

    resetResult() {
      this.dataSource.filter('tradeGroupSeq','=',3); 
    }

    calculateAverageFromSunday (aggregationInfo, seriesInfo) {
      if (!aggregationInfo.data.length) {
        return null;
      }
      const intervalStart = this.addDays(aggregationInfo.intervalStart, -1);
      const intervalEnd = this.addDays(aggregationInfo.intervalEnd, -1);
      const utilization = seriesInfo._data.filter((element) => {return element.argument >= intervalStart && element.argument < intervalEnd}).map((item) => item.data.utilization);
      //utilization = utilization.map((item) => item.data.utilization);
      if (utilization.length <= 0)
        return null;
      return {
        aisDepDate: new Date(
          (intervalStart.valueOf() + intervalEnd.valueOf()) / 2,
        ),
        utilization: utilization.reduce((a,b)=> a + b, 0) / utilization.length
      };
    };

    customizeTooltip (pointInfo) {
      const { aggregationInfo } = pointInfo.point;
      const start = aggregationInfo && this.addDays(aggregationInfo.intervalStart, -1);
      const end = aggregationInfo && this.addDays(aggregationInfo.intervalEnd, -2);
      const endTmp = aggregationInfo && this.addDays(aggregationInfo.intervalEnd, -1);
      const utilization = start && pointInfo.point.series._data.filter((element) => {return element.argument >= start && element.argument < endTmp}).map((item) => item.data.utilization);
      const handlers = {
        'Average Utilization': (arg) => ({
          text: `${
            !aggregationInfo
              ? `Date: ${arg.argument.toDateString()}`
              : `Interval: ${start.toDateString()} - ${end.toDateString()}`
          }<br/>Utilization: ${arg.value.toFixed(2)} %<br/>Utilization range: ${Math.min.apply(null, utilization).toFixed(2)}% - ${Math.max.apply(null, utilization).toFixed(2)}%<br/>No. of ships:${utilization.length} `,
        })
      };
      return handlers[pointInfo.seriesName](pointInfo);
    };
    addDays(baseDate, days) {
      var date = new Date(baseDate.valueOf());
      date.setDate(date.getDate() + days);
      return date;
    }

    render() {
      return (
        <React.Fragment>
          <Box m={'0 0 20px 0'}>
          <Chart
            id="chart"
            ref={this.setChart}
            dataSource={this.dataSource}
          >
            <CommonSeriesSettings argumentField="aisDepDate" />
            <Series
              axis="utilization"
              color="#e91e63"
              valueField="utilization"
              name="Average Utilization"
            >
              <Point size={7} />
              <Aggregation
                enabled={true}
                calculate={this.calculateAverageFromSunday}
                method={'custom'}
              />
            </Series>

            <ArgumentAxis
              aggregationInterval={{ days: 7 }}
              valueMarginsEnabled={false}
              argumentType="datetime"
            />
            <ValueAxis name="utilization">
              <Title text="Weekly Average Utilization">
                <Font color="#e91e63" />
              </Title>
              <Label>
                <Font color="#e91e63" />
              </Label>
            </ValueAxis>
            <Legend visible={false} />
            <Tooltip
              enabled={true}
              customizeTooltip={this.customizeTooltip}
            />
            
          </Chart>
          </Box>
          
          <text><br></br></text>
              
        </React.Fragment>
      );
    }
  }

  export default utilizationChart;