import React from "react"
import { navigate } from '@reach/router';
import { setUser, hasRole } from "../../utils/auth"
import { Box } from "../Layout"
import { TextBox } from 'devextreme-react';
import { Button } from 'devextreme-react';
import { LoadPanel } from 'devextreme-react/load-panel';
import Link from 'gatsby-link';
import colors from '../../utils/colors';  
import { useState} from "react"
import { isLoggedIn, isBrowser } from "../../utils/auth"
import { useFirebase } from "gatsby-plugin-firebase"

const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
};

class SignInBase extends React.Component {
  constructor(props) {
    super(props);
    
    const qs = require('query-string');
    const parsed = isBrowser()?qs.parse(window.location.search):null;
    this.returnUrl = parsed.returnUrl;

    this.state = {loadPanelVisible: false, ...INITIAL_STATE };
    this.onLoginClick = this.onLoginClick.bind(this);
  }
  componentDidMount() {
    this.emailField.focus();
  }
  onLoginClick() 
  {
    const { email, password } = this.state;

    if (!email || !password) {
      this.setState({error: {message: 'Please enter Login Email and Password.'}});
      return;
    }
    this.setState({loadPanelVisible: true});
    this.props.firebase.auth()
      .signInWithEmailAndPassword(email, password)
      .then((result) => {
        this.setState({ ...INITIAL_STATE });
        setUser(result.user, () => {
          if (this.returnUrl)
            navigate(this.returnUrl);
          else 
          {
            if (hasRole(['general','advance','subscribe1']))
              navigate('/');
            else {
              navigate('/subscription');
            }
          }
        });
      })
      .catch(error => {
        if (error.code === 'auth/wrong-password' ||  error.code === 'auth/user-not-found')
          this.setState({loadPanelVisible: false, error: {message: 'Invalid Email or Password!'}});
        else
          this.setState({ loadPanelVisible: false, error });
      });
  }

  render() {
    return (
      <Box bg={colors.primary}>
      <Box
      width={[1, 1, 1 / 2]}
      m={['3.5rem 0 3rem 0', '3.5rem 0 3rem 0', '3.5rem auto 20rem auto']}
      px={[3, 3, 0]}
      color={colors.secondary}
      >
        <h2>Welcome to Linerlytica</h2>
        <Box
            width={[1, "400px", "400px"]} 
              >
          <div className="dx-field">
            <div className="dx-field-label">Email</div>
            <div className="dx-field-value">
              <TextBox placeholder="Your Login Email" 
              value={this.state.email}
              onValueChanged={(data)=> {this.setState({email: data.value});}}
              ref={(ref)=> {if (ref) this.emailField = ref.instance;}}
              onEnterKey={() => {this.passwordField.focus();}}/>
            </div>
          </div>
          <div className="dx-field">
            <div className="dx-field-label">Password</div>
            <div className="dx-field-value">
              <TextBox mode="password"
                placeholder="Enter password"
                value={this.state.password}
                onValueChanged={(data)=> {this.setState({password: data.value});}}
                ref={(ref)=> {if (ref) this.passwordField = ref.instance;}}
                onEnterKey={this.onLoginClick}/>
            </div>
          </div>
          <div style={{textAlign:'right'}}>
            <Button
            width={120}
            text="Login"
            icon="user"
            stylingMode="contained"
            onClick={this.onLoginClick}
          />
          </div>
          <p>
          <span style={{float:"right"}}>Forget password? <Link to={'/app/forgetPassword'} style={{color: colors.secondary}}>Reset here</Link></span>
          <br/>
          <span style={{float:"right"}}>Not a user yet? <Link to={'/register'} style={{color: colors.secondary}}>Register here</Link></span>
          </p>
        </Box> 
          
        {this.state.error && <p>{this.state.error.message}</p>}
        <LoadPanel visible={this.state.loadPanelVisible}/>
        </Box>    
        </Box>
      );
    }
  
}

const SignIn = () => {
  const [firebase, setFirebase] = useState();

  useFirebase(firebase => {
    setFirebase(firebase);
  }, [])

  if (isLoggedIn()) {
    navigate(`/`)
  }

  return (
    
    <SignInBase firebase={firebase} />

  );

}

export default SignIn
